
.about {
  max-width: 720px;
  margin: auto;
}

p,
ul {
  text-align: left;
}
